@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300);
:root {
  --cor-tema: #006499;
  --cor-tema-claro: #1d8dc9;
  --cor-primaria: rgb(10,99,167);
  --cor-secundaria: rgb(17,131,17);
  --cor-terciaria: rgb(176,22,22);
  --cor-amarelo: rgb(220,204,26);

  --cor-preta: #000;
  --cor-branca: #FFF;
  --cor-cinza-claro: #EEE;
  --cor-cinza-escuro: #222;
  --cor-cinza-medio: rgba(68, 68, 68, 0.747);
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;
  background-color: var(--cor-branca);
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.full-width{width: 100%;}
.full-height{height: 100vh;}

/* FLEX */
.flex{  display: flex;}
.horizontal{flex-direction: row;}
.vertical{flex-direction: column;}
.space-between{justify-content: space-between;}

.flex-1{flex: 1 1;}
.flex-2{flex: 2 1;}
.flex-3{flex: 3 1;}
.flex-4{flex: 4 1;}
.flex-5{flex: 5 1;}
.flex-6{flex: 6 1;}
.flex-7{flex: 7 1;}
.flex-8{flex: 8 1;}

.flex-start{
  justify-content: flex-start;
  align-items: center;
}
.flex-end{
  justify-content: flex-end;
  align-items: center;
}
.flex-center{
  justify-content: center;
  align-items: center;
}

.grid-1{
  width: 100%;
}
.grid-2{
  width: 45%;
}
.grid-3{
  width: 30%
}

/* COMPONENTS */
.Barra-Topo{
  background-color: #006499;
  background-color: var(--cor-tema);
  width: 100%;
  padding: 10px;
  height: 50px;
  justify-content: flex-end;
}

.Barra-Topo a{
  color: #FFF;
  color: var(--cor-branca);
  text-decoration: none;
  font-weight: 600;
  padding: 15px;
  cursor: pointer;
}
.Barra-Topo span{
  color: #FFF;
  color: var(--cor-branca);
  text-decoration: none;
  font-weight: 500;
  padding: 15px;
  cursor: pointer;
}

.Menu{
  background-color: #006499;
  background-color: var(--cor-tema);
  width: 50px;
  box-shadow: 0 0 5px #111;
  margin-top: -16px;
}
.menu-open{
  width: 200px;
}

.Menu a {
  color: #FFF;
  color: var(--cor-branca);
  text-decoration: none;
}

.item-top{
  padding: 10px 10px 0 10px;
  font-size: 1.1rem;
  cursor: pointer;
  color: #FFF;
  color: var(--cor-branca);
  justify-content: space-around;
}

.menu-item{
height: 50px;
}
.menu-item:hover, .menu-item-active{
  background-color: #1d8dc9;
  background-color: var(--cor-tema-claro);
}

.h1Inicio {
  font-size: 1.2rem !important;
  text-align: center !important;
}

.menu-item i {
  font-size: 1.2rem;
}
.Card Titulo {
  padding:0px;
}
.Card-Inscricao{
  margin: 40px 20px;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 0 5px #7777;
  background-color: #FFF;
  background-color: var(--cor-branca);
}
.Container-Inscricao{
  padding: 30px;
}
.Card, .Sub-Card{
  margin: 40px 20px;
  padding: 30px;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 0 5px #7777;
  background-color: #FFF;
  background-color: var(--cor-branca);
}
.Sub-Card{
  margin-top: 0;
}

.Pesquisa input{
padding: 8px 15px;
border: 1px solid #cccc;
font-size: 1rem;
border-radius: 10px;
width: 80%;
}
.Pesquisa button {
  margin-left: -40px;
  background-color: transparent;
  font-size: 1rem;
  border: 0;
  cursor: pointer;
}
.TabelaSimples {
 margin-bottom: 50px;
 border-collapse: collapse;
}
.simples{
  border-collapse: collapse;
}
.TabelaSimples td{
  padding: 10px;
  font-size: 0.9rem;
  border-bottom: 1px solid #999;
  text-align: center;  
}
.TabelaSimples th{
  background-color: #006499;
  background-color: var(--cor-tema);
  padding: 10px;
  color: #FFF;
  color: var(--cor-branca);  
  font-size: 0.85rem;
}
.TabelaSimples th:first-child {
 border-top-left-radius: 10px;
 border-bottom-left-radius: 10px;
}
.TabelaSimples th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
 }

 .button{
   font-size: 1rem;
   padding: 10px 15px;
   margin: 5px;
   border: 1px solid #aaaa;
   cursor: pointer;
   border-radius: 10px;
   font-weight: bold;
 }
.button-small{
  font-size: 0.8rem;
  padding: 5px 8px;
  margin: 3px;
}
 .button-modal{
  background-color: #1d8dc9;
  background-color: var(--cor-tema-claro);
  color: #FFF;
  color: var(--cor-branca);
  border: 0;
  width: 200px;
}
 .button-info{
  background-color: #1d8dc9;
  background-color: var(--cor-tema-claro);
  color: #FFF;
  color: var(--cor-branca);
  border: 0;
}

.button-success{
  background-color: rgb(17,131,17);
  background-color: var(--cor-secundaria);
  color: #FFF;
  color: var(--cor-branca);
  border: 0;
}
 .button-danger{
   background-color: rgb(176,22,22);
   background-color: var(--cor-terciaria);
   color: #FFF;
   color: var(--cor-branca);
   border: 0;
 }
 .button-error{
  background-color: rgb(235, 54, 54);
  color: #FFF;
  color: var(--cor-branca);
  border: 0;
}
.button-login{
  width: 350px;
  border-radius: 5px;
  background-color: #007bff;
  height: 50px;
  color: white;
}
 /* main{
   overflow-y: scroll;
 } */
 main > div {
   margin-right: 20px;
 }

 .Paginacao{
   display: flex;
   justify-content: flex-end;
 }
 .paginacao-item{
font-size: 0.9rem;
color: #222;
color: var(--cor-cinza-escuro);
padding: 5px 10px;
border: 1px solid #cccc;
cursor: pointer;
 }

 .paginacao-item:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
 }
 .paginacao-item:last-child {
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
  }
 .paginacao-item:hover,.paginacao-item-active{
   background-color: #222;
   background-color: var(--cor-cinza-escuro);
   color: #EEE;
   color: var(--cor-cinza-claro);
   border: 1px solid #222;
   border: 1px solid var(--cor-cinza-escuro)
}

.Link-Voltar{
  text-decoration: none;
  color: rgb(10,99,167);
  cursor: pointer;
}
.Input-Select{
  width: auto;
}
.Input-Simples{
  justify-content: flex-end;
}
.Input-Simples label{
  margin: 5px 0 2px 0;
  font-weight: bold;
}
.Input-Simples input, textarea{
  padding: 8px 12px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #cccc;
  font-size: 0.95rem;
}
.Input-Simples-Modal{
   justify-content: space-between;
   align-items: center;

}
.Input-Simples-Modal label{
  margin: 5px 0 2px 0;
  font-weight: bold;
}
.Input-Simples-Modal input, textarea{
  padding: 8px 12px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #cccc;
  font-size: 0.95rem;
}

.Input-Simples-cep{
   align-items: center;
}
.Input-Simples-cep label{
  margin: 5px 0 2px 0;
  font-weight: bold;
  align-self: flex-start;
}
.Input-Simples-cep small{
  align-self: flex-start;
}
.Input-Simples-cep input, textarea{
  padding: 8px 12px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #cccc;
  font-size: 0.95rem;
  width: 95%;
}
.Input-Simples-cep button{
  background-color: transparent;
  font-size: 1rem;
  border: 0;
  cursor: pointer;
  margin-top: -30px;
  align-self: flex-end;
}
.Formulario button{
   background-color: transparent;
  font-size: 1rem;
  border: 0;
  cursor: pointer;
  margin-bottom: -80px;
}

.Input-Simples-Cpf{
  justify-content: flex-end;
}
.Input-Simples-Cpf label{
  margin: 5px 0 2px 0;
  font-weight: bold;
}
.Input-Simples-Cpf input, textarea{
  padding: 8px 12px;
  margin: auto;
  border-radius: 5px;
  border: 1px solid #cccc;
  font-size: 0.95rem;
}
.Input-Simples-Text{
  margin: 5px 20px 5px 0;
  font-weight: bold;
}
.Input-Simples-Text label{
  margin: 5px 0 5px 0;
  font-weight: bold;
}
.Input-Simples-Text input, textarea{
  padding: 8px 12px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #cccc;
  font-size: 0.95rem;
}
textarea{
  width: 90%
}

.input-error{
  border: 1px solid red;
  box-shadow: 0 0 5px red;
}
.small-danger{
  color: red;
  font-size: 12px
}

.border-green{
  border-left: 4px solid rgb(17,131,17);
  border-left: 4px solid var(--cor-secundaria);
  padding-left: 5px;
  margin: 2px 0;
}

.Input-Valor {
  margin: 10px 0;
  }
  .Input-Valor input, .Input-Valor .input {
    border: 1px solid #222;
    border: 1px solid var(--cor-cinza-escuro);
    padding: 8px 12px;
    font-size: 0.95rem;
    border-radius: 10px;
    }

    .Input-Valor .input{
      background-color: #EEE;
      background-color: var(--cor-cinza-claro);
      color: rgba(68, 68, 68, 0.747);
      color: var(--cor-cinza-medio);
    }
    .Input-Valor .input-nostyle{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Login .Card{
      width: 95%;
      max-width: 450px;
    }
    .Login h1{
      font-size: 3rem;
      color: rgb(20,3,61);
      margin-bottom: 0;
    }
    .Recuperar-Senha h1{
      font-size: 3rem;
      color: rgb(20,3,61);
      margin-bottom: 0;
    }
    .Resetar-Senha h1{
      font-size: 3rem;
      color: rgb(20,3,61);
      margin-bottom: 0;
    }
    .Login a {
      text-decoration: none;
      color: rgba(68, 68, 68, 0.747);
      color: var(--cor-cinza-medio);
    }

    .Imagens-Container{
      flex-wrap: wrap;
      display: flex;
    }
    .imagem-container{
      max-width: 100px;
      height: 100px;
      margin: 5px;
      border-radius: 5px;
      background-size: cover;
      flex: 1 1;
      flex-basis: 100px;
    }
    .Imagem-remover{
      background-color: rgb(176,22,22);
      background-color: var(--cor-terciaria);
      width: 20px;
      height: 20px;
      font-size: 1rem;
      color: #FFF;
      color: var(--cor-branca);
      border-radius: 100px;
      cursor: pointer;
      font-weight: bold;
    }
    .Imagem-remover:hover{
      background-color: #a10b0b;     
    }

    .variacao-item{
      background-color: #EEE;
      background-color: var(--cor-cinza-claro);
      color: #222;
      color: var(--cor-cinza-escuro);
      padding: 10px 15px;
      border-radius: 10px;
      margin: 5px 0;
      width: 100%;
      cursor: pointer;
    }

    .variacao-item-ativa, .variacao-item:hover{
      background-color: #222;
      background-color: var(--cor-cinza-escuro);
      color: #EEE;
      color: var(--cor-cinza-claro);
    }
    
    .alert{
      width: calc(100% -20px);
      margin: 10px 0;
      padding: 10px 15px;
      border-radius: 10px;
      border: 2px solid rgba(68, 68, 68, 0.747);
      border: 2px solid var(--cor-cinza-medio);
      background-color: #EEE;
      background-color: var(--cor-cinza-claro);
      color: #222;
      color: var(--cor-cinza-escuro);
      font-size: 0.9rem;
    }
    .alert-danger{
      border: 2px solid rgb(176,22,22);
      border: 2px solid var(--cor-terciaria);
      background-color: #f3a6a6;
      color: #800909
    }
    .alert-success{
      border: 2px solid rgb(17,131,17);
      border: 2px solid var(--cor-secundaria);
      background-color: #bff3ba;
      color: #175c11
    }
.Texto-Dados{
  font-size: 1rem;
  font-weight:bold ;
  height: auto;
  width: 30%;
  margin: 5px 0 2px 0;
  align-self: flex-end;  
}

.Titulo-detalhe{
  background: -webkit-gradient(linear, left top, right top, from(rgba(1, 163, 213, 1)), color-stop(20%, rgba(1, 163, 213, 1)), color-stop(79%, rgba(0, 105, 166, 1)), to(rgba(0, 105, 166, 1)));
  background: linear-gradient(to right, rgba(1, 163, 213, 1) 0%, rgba(1, 163, 213, 1) 20%, rgba(0, 105, 166, 1) 79%, rgba(0, 105, 166, 1) 100%);
  color: white;
  padding: 10px;
  margin: 0;
}
.Titulo-detalhe-inscricao{
  background: -webkit-gradient(linear, left top, right top, from(rgba(1, 163, 213, 1)), color-stop(20%, rgba(1, 163, 213, 1)), color-stop(79%, rgba(0, 105, 166, 1)), to(rgba(0, 105, 166, 1)));
  background: linear-gradient(to right, rgba(1, 163, 213, 1) 0%, rgba(1, 163, 213, 1) 20%, rgba(0, 105, 166, 1) 79%, rgba(0, 105, 166, 1) 100%);
  color: white;
  padding: 10px;
  margin: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.img-logo{
  width: 40% !important;
 display: flex;
 margin: 0 auto;
 padding: 20px;
}
.img-teste{
  background-color: red !important;
}
.SubFooter{
  width: 80%;
  text-align: center;
  align-self: center;  
}

.SubFooter .left{
  text-align: left;
}
.SubFooter .right{
  text-align: right;
}

.Footer{
  float: left;
    width: 100%;
    background-color: #004680;
    text-align: center;
    margin-top: 4rem;
}
.faixa-footer{
  width: 50%;
  height: 10px;
  float: right;
  margin-top: -10px;
  background-color: #06a3d4;
}
.Footer-Container{
  padding: 30px;
 background-size: contain;
 background-image: url(https://www.anapolis.go.gov.br/wp-content/themes/prefeitura-anapolis/assets/images//monumentos-anapolis.png)
}
.Footer-Text p{
  font-size: 13px;
  margin-left: 40px;
  color: white;
}
.Footer-img img {
  width: 100%;
  margin-left: 20px;
}
.Footer-Text-right{
 margin-left: -10px; 
 color: white
}

.input-login{
  width: 350px;
  height: 50px;
  border: none;
  border-radius: 5px;
  box-shadow: 8px 10px 20px 0 rgba(46, 61, 73, 0.15);
  margin-top: 20px;
}

@media only screen and (max-width: 940px) {
  .Footer-Container{
    flex-direction: column;
  }
  .Footer-img img{
    margin: 0;
  }
  .Footer-Text p{
    margin: 20px 0;
  }
  .Input-Simples-Text{
    width: 100%;
  }
  .button-modal{
    width: 20vh;
  }
  .Modal{
    display: block !important;
    overflow-x: scroll;
  }
  .Input-Simples-Modal input, textarea{
    width: 100%;
  }
  .Input-Simples-Modal{
    flex-direction: column;
  }
  .TabelaSimples{
    overflow-y: scroll;
  }
  .Container-Inscricao{
    padding: 10px;
    text-align: justify;
  }
  .Input-Inscricao{
    flex-direction: column;
  }
  .Card-Inscricao{
    margin: 0;
  }
  .Card, .Sub-Card{
    padding: 0;
  }
  .MuiBox-root-6{
    padding: 0;
  }
  .Input-Simples{
    width: 100%;
  }
  .Texto-Dados{
    align-self: flex-start;
    width:80%;
  }
  .input-login{
width: auto;
  }
  .opcoes-login{
    width: auto;
  }
  .btn-login{
    width: auto;
  }
  .Barra-Topo{
    width: 100%;
    height: 35px;
  }
  .Barra-Topo a{
    width: auto;
    padding: 0px !important;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
  }
  .Footer{
    width: auto !important;
    text-align: center;
  }  
  .SubFooter{
    width: auto;
    align-items: center;
    flex-direction: column;
  }
  .Candidato{
    margin: 10px !important;
    width: 90% !important;
  }

  .Endereco{
    text-align: center !important;
  }
  .Duvidas{
    text-align: center !important;
  }
  .Inscricao{
    text-align: '-webkit-center';
  }
  .Upload-Docs {
text-align: '-webkit-center';
  }
  .dados-inscricao{
    display: flex;
    flex-direction: column;
  }
  .Titulo-detalhe{
    width: -webkit-fill-available;
  }

  .Input-Upload{
    flex-direction: column;
  }
  .Input-Upload-Item{
    width: auto !important;
  }
}



/* CANDIDATO */

.Candidato{
  border: 1px solid #999;
  border-radius:15px;
  padding: 10px;
  margin: 0 auto;
  width: 90%;
  font-size: 1.1rem;
}
.Modal{
display: flex;
    align-items: center;
    justify-content: center;
}
.Card-Modal{
  background-color: rgb(231, 231, 231);
    border: '2px solid #000';
    box-shadow:inset 0 0 1em rgba(112, 112, 112, 0.438), rgba(63, 62, 62, 0.452);
    padding: 40px
}
.Instrucao-Integrante-Familiar{
  font-size: 1rem;
}
.Renda-Grupo-Familiar{
  justify-content: center;
  align-items: center;
}
.Valor-Renda{
  color: rgb(133, 130, 130);
}
.Table-Integrantes{
  
  width: 100%;
}
.botoes{
  background-color: red;
}
body {
  font-family: "Lato", sans-serif;
}
.container-upload{
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.form {
  width: 400px;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 60px;
}
.file-upload-wrapper:after {
  content: attr(data-text);
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(230, 238, 255);
  padding: 10px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: auto;
  line-height: 40px;
  color: #999;
  border-radius: 5px 10px 10px 5px;
  font-weight: 300;
  content-visibility: auto
}
.file-upload-wrapper:before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: 60px;
  background: #4daf7c;
  color: #fff;
  font-weight: 700;
  z-index: 25;
  font-size: 16px;
  line-height: 60px;
  padding: 0 15px;
  text-transform: uppercase;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}
.file-upload-wrapper:hover:before {
  background: #3d8c63;
}
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

/* DETALHES DO CANDIDATO */
.tg  {border-collapse:collapse;border-spacing:0; width: 100%; background-color: rgba(226, 226, 226, 0.63);box-shadow: 1px 1px 2px 3px rgba(0, 0, 0, 0.3);}
.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-0lax{text-align:left;vertical-align:top}
.negrito{
  font-weight: 700;
}
.integrantes{
  margin-bottom: 20px;
}

.Span-Container{
  margin: 20px 0 0 0;
  font-weight: 700;
  font-size: 1.1rem;
  padding: 10px;
  background-color: #006499 !important;
  color: white;
  box-shadow: 1px 1px 2px 3px rgba(0, 0, 0, 0.3) !important;
  text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.8);
}

.App-NavBar{
  margin: 20px 0 0 0;
  font-weight: 700;
  font-size: 1.1rem;
  background-color: #006499 !important;
  color: white;
  box-shadow: 1px 1px 2px 3px rgba(0, 0, 0, 0.3) !important;
  text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.8);
}

